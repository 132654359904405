import { useDisclosure, Box, Button, Flex, HStack, Stack, useColorModeValue as mode,Menu,MenuList,MenuItem,MenuButton } from '@chakra-ui/react'
import React,{useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MenuItems from './MenuItems';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { BiChevronDown } from 'react-icons/bi';
import {changeCategoryLabel} from '../redux/slices/categorySlice';

const DesktopNavItem = (props) => {
  const { isActive, label, href = '#', ...rest } = props;
  const companysetting  = useSelector((state) => state.company);
  const maincolor = (companysetting.length > 0 && companysetting[0].DATA.theme_maincolor != null) ? companysetting[0].DATA.theme_maincolor : "#7c1c1c";
  const hovercolor = (companysetting.length > 0 && companysetting[0].DATA.theme_maincolor != null) ? companysetting[0].DATA.theme_maincolor : "#7c1c1c";
  const fontcolor = (companysetting.length > 0 && companysetting[0].DATA.theme_fontcolor != null) ? companysetting[0].DATA.theme_fontcolor : "#FFF";
  
  let navigate = useNavigate();
  {/* /ecommerce Remove  */}
  return (
    <Flex
      as="a"
      //href={href}
      onClick={()=> {navigate(`/filter/${label}`)}}
      cursor="pointer"
      direction="column"
      justify="center"
      minH="10"
      fontSize="sm"
      fontWeight="medium"
      position="relative"
      aria-current={isActive ? 'page' : undefined}
      color={mode('white', 'white')}
      _activeLink={{
        borderBottomWidth: '2px',
        borderColor: 'currentColor',
        color: mode('white', 'white'),
      }}
      _hover={{
        color: mode('white', 'white'),
      }}
      {...rest}
    >
      {/* <Button variant='link' colorScheme='white' style={{textDecoration:"none"}} onClick={()=> {history.push(`/product_list/${label}`)}}> */}
      {label}
      {/* </Button> */}
    </Flex>
  )
}
const MobileNavItem = (props) => {
  const { label, href, isActive, ...rest } = props
  return (
    <Box
      as="a"
      href={href}
      aria-current={isActive ? 'page' : undefined}
      py="2"
      px="3"
      _activeLink={{
        bg: mode('white', 'gray.600'),
        shadow: 'base',
      }}
      {...rest}
    >
      {label}
    </Box>
  )
}
const NavItem = {
  Desktop: DesktopNavItem,
  Mobile: MobileNavItem,
}
const menus = [
  {
    label: 'All Categories',
    href: '/',
  },
  {
    label: 'Electronics',
    href: '#',
  },
  {
    label: 'Women',
    href: '#',
  },
  {
    label: 'Men',
    href: '#',
  },
  {
    label: 'Baby & Toys',
    href: '#',
  },
  {
    label: 'Grocery',
    href: '#',
  },
  {
    label: 'Winery',
    href: '#',
  },
]
const DesktopNavCategoryMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const category_arr = useSelector((state) => state.category.category);
  const [catlabel,setCatLabel] = useState("");
  let navigate = useNavigate();
  const companysetting  = useSelector((state) => state.company);
  const maincolor = (companysetting.length > 0 && companysetting[0].DATA.theme_maincolor != null) ? companysetting[0].DATA.theme_maincolor : "#7c1c1c";
  const hovercolor = (companysetting.length > 0 && companysetting[0].DATA.theme_maincolor != null) ? companysetting[0].DATA.theme_maincolor : "#7c1c1c";
  const fontcolor = (companysetting.length > 0 && companysetting[0].DATA.theme_fontcolor != null) ? companysetting[0].DATA.theme_fontcolor : "#FFF";
  const base_hosturl = useSelector(state => state.baseurl.basehostname); //window.location.hostname; //"gadgets.ondemandcrm.co"; 
  const catLabel = useSelector((state) => state.category.label);
  const dispatch = useDispatch();
  return (
    <Box
      //borderTopWidth="1px" //Comment by ankit on 04/04/2023
      //borderBottomWidth="1px" //Comment by ankit on 04/04/2023
      //borderColor={mode('gray.200', 'gray.700')} //Comment by ankit on 04/04/2023
      bg={mode(`${maincolor}`, 'gray.800')}
      px="8"
    >
      <Box maxW="8xl" mx="auto" >
        <HStack spacing="8">
          <NavItem.Desktop key={"ALL"}  />
          {/* <Menu> */}
            {/* /ecommerce Remove  */}
            {category_arr.slice(0,7).map((menu, index) => {
              return <MenuItems items={menu} isActive={menu.label === catlabel} setCatLabel={setCatLabel} key={menu.label}  />;
            })}

            {category_arr.length > 7 &&
              <>
              <Menu>
              <MenuButton 
                    as={Button}  
                    size='md'
                    fontWeight='400'
                    variant='ghost' 
                    colorScheme='#22543d'  
                    className='navButtonCls' 
                    aria-current={catLabel === "more" ? 'page' : undefined}
                    rightIcon={<BiChevronDown />}
                    color={mode(`${fontcolor}`, `${fontcolor}`)}
                    paddingInlineStart={0}
                    _activeLink={{
                        borderBottomWidth: '2px',
                        borderColor: 'currentColor',
                        color: mode(`${fontcolor}`, `${fontcolor}`),
                        background:`${hovercolor}`,
                        padding:'6px',
                        fontWeight:'600',
                        borderRadius:'0px'
                    }}
                    _hover={{
                        color: mode(`${fontcolor}`, `${fontcolor}`),
                    }}
                    onMouseEnter={onOpen}
                    onMouseLeave={onClose}
                   // onClick={()=> {navigate(`/ecommerce/filter/${items.label.replace(/\s+/g, '-')}`);dispatch(changeCategoryLabel(items.label));}}
                    >
                  More
                </MenuButton>
                <MenuList>
                  {category_arr.slice(7).map((menu_sl, index_sl) => {
                    return <MenuItem
                    textTransform="capitalize" 
                      key={index_sl}
                      onClick={()=> {navigate(`/filter/${menu_sl.label.replace(/\s+/g, '-')}`);dispatch(changeCategoryLabel("more"));}}
                      >{menu_sl.label}</MenuItem>
                  })}
                </MenuList>
              </Menu>
              </>
            }

            {(base_hosturl == "vanillathebakery.ondemandcrm.co" || base_hosturl == "cakes.ondemandcrm.co") &&
            <Flex
            as="a"
            //href={href}
            onClick={()=> {navigate(`/customizecake`);setCatLabel(`Customize Cake`);}}
            cursor="pointer"
            direction="column"
            justify="center"
            minH="10"
            fontSize="sm"
            fontWeight="medium"
            position="relative"
            aria-current={'Customize Cake' === catlabel ? 'page' : undefined}
            color={mode('white', 'white')}
            _activeLink={{
                borderBottomWidth: '2px',
                borderColor: 'currentColor',
                color: mode('white', 'white'),
                background:`${hovercolor}`,
                padding:'6px',
                fontWeight:'600'
            }}
            _hover={{
                color: mode('white', 'white'),
            }}
            //{...rest}
            >
            Order Custom Cake
            </Flex>
          }
          {/* </Menu> */}
          {/* {category_arr.map((link) => (
            <NavItem.Desktop key={link.label} {...link} isActive={link.label === 'Men'} />
          ))} */}
        </HStack>
      </Box>
    </Box>
  )
}
const MobileNavCategoryMenu = () => {
  const [catlabel,setCatLabel] = useState("");
  let navigate = useNavigate();
  return (
    <Box
      width="32%"
      minW="100px"
      bg={mode('gray.50', 'gray.700')}
      color={mode('gray.600', 'gray.100')}
      borderEndWidth="1px"
      py="6"
    >
      <Stack spacing="1">
        <>
        {/* /ecommerce Remove  */}
        {menus.map((link) => (
          <NavItem.Mobile key={link.label} {...link} isActive={link.label === 'Men'} />
        ))}
        <Box
        as="a"
       // href={href}
        onClick={()=> {navigate(`/customizecake`);setCatLabel(`Customize Cake`);}}
        //aria-current={isActive ? 'page' : undefined}
        py="2"
        px="3"
        _activeLink={{
          bg: mode('white', 'gray.600'),
          shadow: 'base',
        }}
        //{...rest}
      >
       Order Custom Cake
      </Box>
        </>
      </Stack>
    </Box>
  )
}

export const NavCategoryMenu = {
  Mobile: MobileNavCategoryMenu,
  Desktop: DesktopNavCategoryMenu,
}
