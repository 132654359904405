import React, { useState, useEffect } from 'react';
import {
  Container,
} from '@chakra-ui/react';
import { useParams } from "react-router-dom";
import { AppProduct } from './ProductDetail/AppProduct';
import DynamicButton from './DynamicButton';
import http from "../http-common";
import { Base64 } from 'js-base64';
import { Header } from '../header/Header';
import Footer from '../Footer/Footer';
import { useSelector } from 'react-redux';


export default function ProductDetail() {
  let { product_id } = useParams();
  const [proddetails, setProductDetails] = useState([]);
  const [attr, setAttr] = useState([]);
  const [attrmaster, setAttrMaster] = useState([]);
  const [images, setImages] = useState([]);
  const [loading,setLoading] = useState(true);

  const baseurl = useSelector(state => state.baseurl.baseURL);;  //window.location.origin; //"https://gadgets.ondemandcrm.co";
  async function getSingleProductByName() {
    setLoading(true);
    const spinner = document.getElementById("spinner");
    if(spinner){
        spinner.style.display = "flex";
    }
    let url = '/ecommerce_api/getSingleProductByName/';
    const res = await http.post(url, {
      AUTHORIZEKEY: "",
      product_id: Base64.decode(product_id),
    });
    if (res.data[0].STATUS == "SUCCESS") {
      setProductDetails(res.data[0].DATA.products);
      setAttr(res.data[0].DATA.array_attr);
      setImages(res.data[0].DATA.gallery);
      setAttrMaster(res.data[0].DATA.array_master);
      if(spinner){
        spinner.style.display = "none";
      }
      setLoading(false);
    } else {
      setProductDetails([]);
      setAttr([]);
      setImages([]);
      setAttrMaster([]);
      if(spinner){
        spinner.style.display = "none";
      }
      setLoading(false);
    }
  }
  useEffect(()=>{
    window.scrollTo(0, 0);
  },['']);
  useEffect(() => {
    getSingleProductByName();
  }, []);
  return (
    !loading && (
    <>
    <Header/>
    <Container maxW={'7xl'} >
      <DynamicButton/>
      <AppProduct product_id={Base64.decode(product_id)} proddetails={proddetails} images={images} attr={attr} attrmaster={attrmaster}    />
    </Container>
    <Footer/>
    </>
    )
  );
}
