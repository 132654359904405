import React, { useState, useEffect } from 'react';
import {
  Box,Container,Text,Stack,StackDivider,
  Accordion,AccordionItem,AccordionButton,AccordionPanel,AccordionIcon,Heading,
  Card,CardHeader,CardBody,
  RadioGroup,Radio,Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,useDisclosure,FormControl,FormLabel,Input,FormHelperText,Select,SimpleGrid,WrapItem,Center,Wrap,Checkbox
} from '@chakra-ui/react';
import swal from 'sweetalert';
import { useForm } from "react-hook-form";
import http from "../http-common";

export const Address = ({onClose,address,getAddressMaster}) => {
  const { register,setValue, formState: { errors }, handleSubmit } = useForm();
  const [type, setType] = useState();
  const [country, setCountry] = useState([]);
  const [State, setState] = useState([]);
  const [selcountry, setSelCountry] = useState("");
  const [checkedItems, setCheckedItems] = React.useState(false);
  const [btndisable,setButtonDisable] = useState(false);
  const [addcontact, setAddContact] = useState({ name: "", mobile: "", email: "", address1: "", address2: "", country: "", state: "", city: "", zipcode: "", gstnumber: "" });
  useEffect(() => {
    getCountryList();
  }, []);
  const getCountryList = e => {
    async function findCountryList() {
        let url = '/expo_access_api/getCountryList/';
        const res = await http.post(url, {
            AUTHORIZEKEY: "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy",
        });
        if (res.data[0].STATUS == "SUCCESS") {
            setCountry(res.data[0].DATA);
            //setValue("country","India");
            changestate(e,"India");
        } else {
            console.log("undone")
        }
    }
    findCountryList();
}
const changestate = (e, val) => {
    var element_val = val == 0 ? e.target.value : val;
    setSelCountry(element_val);
    async function getStates() {
        const res = await http.post("/expo_access_api/getstatelist/", {
            AUTHORIZEKEY: "c34FxCQy4DCGCHA9zlTgqTsywZJSW8hy",
            COUNTRY: element_val,
        });
        if (res) {
            if (res.data[0].STATUS === "SUCCESS") {
                if (res.data[0].DATA.state) {
                    setState(res.data[0].DATA.state);
                    //setValue("state","GUJARAT");
                }
            } else {
                swal("Not Found", "State Not Found", "danger");
                setState([]);
            }
        }
    }
    getStates();
};
useEffect(()=>{
    console.log("HELLO");
    setValue("contact_id",localStorage.getItem("contact_id"));
    console.log(Array.isArray(address));
    if(Array.isArray(address) == false){
        setValue("address_id",address.contact_address.Contact_address.address_id);
        setValue("address_type",address.contact_address.Contact_address.nickname);
        setValue("address1",address.contact_address.Contact_address.address_1);
        setValue("address2",address.contact_address.Contact_address.address_2);
        const timer = setTimeout(() => {
        setValue("country",address.contact_address.Contact_address.country);
        setValue("state",address.contact_address.Contact_address.state);
        }, 1000);
        setValue("city",address.contact_address.Contact_address.city_name);
        setValue("zipcode",address.contact_address.Contact_address.zipcode);
        setValue("gstnumber",address.contact_address.Contact_address.gstno);

        console.log("BillingFlag");console.log(address.contact_address.Contact_address.billing_flg);
        setCheckedItems(address.contact_address.Contact_address.billing_flg == "Y" ? true : false);
        //setValue("billing_flag",true);
        setType(1);
        return () => clearTimeout(timer);
    }else{
        setValue("address_id","");
        setValue("address_type","");
        setValue("address1","");
        setValue("address2","");
        const timer = setTimeout(() => {
        setValue("country","");
        setValue("state","");
        }, 1000);
        setValue("city","");
        setValue("zipcode","");
        setValue("gstnumber","");
        setCheckedItems(false);
        setType(0);
        //setValue("billing_flag",true);
        return () => clearTimeout(timer);
    }
  },[address]);
  const onSubmit = data =>{
    setButtonDisable(true);
    http.post(`/ecommerce_api/updateContactAddress/`, {
        contact_address: data,
    }).then(res => {
        if (res.data[0].STATUS == "SUCCESS") {
            swal(res.data[0].MSG);
            setButtonDisable(false);
            getAddressMaster();
            onClose();
        } else {
            setButtonDisable(false);
        }
    }).catch(err => {
        swal("Something went wrong", "error");
        setButtonDisable(false);
    });
  } 
  return (
    <>
      <Card>
          <CardBody>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack
                    //maxW="xs"
                    mx="auto"
                    py={{ base: '6', md: '8' }}
                    spacing={{ base: '6', md: '6' }}
                >
                    {/* <Heading
                        as={'h5'}
                        fontSize={{ base: 'lg', sm: 'xl' }}
                    >
                        Edit Address
                    </Heading> */}
                    <Stack
                        spacing="12"
                        direction={{
                            base: 'column',
                            md: 'row',
                        }}
                    >
                        <FormControl id="address1" isRequired>
                            <FormLabel ><b>Address Type</b></FormLabel>
                            <Input placeholder='Address Type' bg={'gray.100'}  {...register("address_type")} />
                            <Input type="hidden"  bg={'gray.100'}  {...register("address_id")} />
                            <Input type="hidden"  bg={'gray.100'}  {...register("contact_id")} />
                        </FormControl>
                    </Stack>
                    <Stack
                        spacing="6"
                        direction={{
                            base: 'column',
                            md: 'row',
                        }}
                    >
                        <FormControl id="address1" isRequired>
                            <FormLabel ><b>Addrss Line 1</b></FormLabel>
                            <Input placeholder='Addrss Line 1' bg={'gray.100'}  {...register("address1", { required: true })} />
                        </FormControl>
                        <FormControl id="address2">
                            <FormLabel ><b>Addrss Line 2</b></FormLabel>
                            <Input placeholder='Addrss Line 2' bg={'gray.100'}  {...register("address2")} />
                        </FormControl>
                    </Stack>
                    <Stack
                        spacing="6"
                        direction={{
                            base: 'column',
                            md: 'row',
                        }}
                    >
                        <FormControl id="country" isRequired>
                            <FormLabel ><b>Country</b></FormLabel>
                            <Select placeholder='Select Country'
                                size='md'
                                bg={'gray.100'}
                                {...register("country", { required: true })}
                                onChange={(e) => { changestate(e, 0) }}
                            >
                                {country.map((cntry) => (
                                    <option key={cntry.Country.country_code} value={cntry.Country.country_name}>
                                        {cntry.Country.country_name}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl id="state" isRequired>
                            <FormLabel><b>State</b></FormLabel>
                            <Select
                                placeholder='Select State'
                                size='md'
                                bg={'gray.100'}
                                {...register("state", { required: true })}
                                onChange={(e) => { setAddContact({ ...addcontact, state: e.target.value }) }}
                            >
                                {State
                                    ?
                                    [State.map((S) => (
                                        <option key={`state_${S.State.state_name}`} value={S.State.state_name}>{S.State.state_name}</option>
                                    ))]
                                    : ""
                                }
                            </Select>
                        </FormControl>
                        <FormControl id="city" isRequired>
                            <FormLabel><b>City</b></FormLabel>
                            <Input placeholder='City' bg={'gray.100'} {...register("city", { required: true })} />
                        </FormControl>
                    </Stack>
                    <Stack
                        spacing="6"
                        direction={{
                            base: 'column',
                            md: 'row',
                        }}
                    >
                        <FormControl id="zipcode" isRequired>
                            <FormLabel ><b>Zipcode</b></FormLabel>
                            <Input minLength={6} maxLength={6} placeholder='Zipcode' bg={'gray.100'} {...register("zipcode")}   onKeyPress={(event) => {
                                if (!/^[+-]?\d*(?:[.,]\d*)?$/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} />
                        </FormControl>
                        <FormControl id="gstnumber">
                            <FormLabel><b>Gst Number</b></FormLabel>
                            <Input placeholder='Gst Number' bg={'gray.100'} {...register("gstnumber")}   />
                        </FormControl>
                    </Stack>
                    <Stack
                        spacing="12"
                        direction={{
                            base: 'column',
                            md: 'row',
                        }}
                    >
                    <Checkbox 
                        size='md' 
                        colorScheme='green' 
                        {...register("billing_flag")} 
                        defaultChecked
                        isChecked={checkedItems}
                        onChange={(e) => setCheckedItems(e.target.checked)}
                        >
                        Make this my default address
                    </Checkbox>
                    </Stack>
                    <Wrap spacing='30px' justify='center'>
                        <WrapItem>
                            <Center>
                                <Button variant='outline' colorScheme='gray' size='md' onClick={onClose}>
                                    Cancel
                                </Button>
                            </Center>
                        </WrapItem>
                        <WrapItem>
                            <Center>
                                <Button colorScheme='teal' size='md' type="submit" disabled={btndisable}>
                                    {type == 1 ? "Update" : "Save" }
                                </Button>
                            </Center>
                        </WrapItem>
                    </Wrap>
                </Stack>
            </form>
          </CardBody>
      </Card>
    </>
  )
}
