import React from 'react'
import { Header } from '../header/Header'
import Footer from '../Footer/Footer'
import {
    Box,
    Button,
    HStack,
    Icon,
    Stack,
    Tag,
    Text,
    useColorModeValue,
    Wrap,  Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,Heading,LinkBox,LinkOverlay,
    FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,Input
  } from '@chakra-ui/react'
  import { ChevronRightIcon } from '@chakra-ui/icons'
export const Profile = () => {
  return (
    <>
        <Header />
            <Box
                borderWidth='1px' 
                rounded='md'
                maxW="4xl"
                mx="auto"
                className="Ecomm-Group"
                //p={4}
                //background={"gray.200"}
                px={{
                    base: '4',
                    md: '8',
                    lg: '12',
                }}
                py={{
                    base: '6',
                    md: '8',
                    lg: '12',
                }}
            >
                <Breadcrumb  separator={<ChevronRightIcon color='gray.500' />}>
                    <BreadcrumbItem>
                        <BreadcrumbLink href='#'>Your Account</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink href='#'>Profile & Security</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
                <Stack spacing={6}>
                    <Heading as='h1' size='lg' noOfLines={1}>
                        Profile & Security
                    </Heading>
                    <FormControl isRequired>
                        <FormLabel>Name</FormLabel>
                        <Input placeholder='First name' />
                    </FormControl>
                </Stack>
            </Box>
        <Footer/>
    </>
  )
}
