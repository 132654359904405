import {
  Box,
  Flex,
  Heading,
  HStack,
  Link,
  Stack,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import React,{ useState } from 'react'
import { CartItem } from './CartItem'
import { CartOrderSummary } from './CartOrderSummary'
import { useSelector } from 'react-redux';
import http from "../../http-common";
import { useEffect } from 'react';
import { ClockLoader } from 'react-spinners';

export const AppCart = ({cartArray,navigate,totalQty}) => {
  const companysetting  = useSelector((state) => state.company);
  const [cartar,setCartArr] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [trans_type,setTransactionType] = React.useState();
  const calculateTax = async() =>{
    setIsLoading(true);
    let url = '/ecommerce_api/calculateTaxForCart/';
    const res = await http.post(url, {
        AUTHORIZEKEY: "",
        contact_id: localStorage.getItem("contact_id"),
        cart:cartArray
    }).then((res) => {
      if (res.data[0].STATUS == "SUCCESS") {
        setCartArr(res.data[0].DATA.cart_array);
        setIsLoading(false);
      } else {
        setCartArr(cartArray);
        setIsLoading(false);  
      }
    });
  }
  useEffect(()=>{
    const transaction_type = (companysetting.length > 0 ? companysetting[0].DATA.theme_transaction_type : 0);
    if(transaction_type == 22){
      calculateTax();
    }else{
      setCartArr(cartArray);
    }
    setTransactionType(transaction_type);
  },[cartArray])

  return (
  <Box
    maxW={{
      base: '3xl',
      lg: '7xl',
    }}
    mx="auto"
    px={{
      base: '4',
      md: '8',
      lg: '12',
    }}
    py={{
      base: '6',
      md: '8',
      lg: '12',
    }}
  >
    <Stack
      direction={{
        base: 'column',
        lg: 'row',
      }}
      align={{
        lg: 'flex-start',
      }}
      spacing={{
        base: '8',
        md: '16',
      }}
    >
      <Stack
        spacing={{
          base: '8',
          md: '10',
        }}
        flex="2"
      >
        <Heading fontSize="2xl" fontWeight="extrabold">
          {(companysetting.length > 0 && companysetting[0].DATA.label_title_cart != null) ? companysetting[0].DATA.label_title_cart : "Shopping Cart"} ({totalQty} items)
        </Heading>
        {isLoading ? (
          <div className="spinner-container">
            <ClockLoader  color="#000" />
          </div>
        ) : (
          <Stack spacing="6">
            {cartar.map((item) => (
              <CartItem key={item.id} {...item} trans_type={trans_type} />
            ))}
          </Stack>
        )}
      </Stack>
      <Flex direction="column" align="center" flex="1">
        <CartOrderSummary cart_array={cartar} trans_type={trans_type}/>
        <HStack mt="6" fontWeight="semibold">
          <p>or</p>
          <Link color={mode('green.800', 'green.200')} onClick={()=> navigate("/filter/ALL")}>Continue shopping</Link>
        </HStack>
      </Flex>
    </Stack>
  </Box>
)}
