import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './features/counter/counterSlice';
import companyReducer from './redux/slices/company';
import { cartReducer } from './redux/slices/cartSlice';
import { categoryReducer } from './redux/slices/categorySlice';
import { productReducer } from './redux/slices/productSlice';
import { timeslotReducer } from './redux/slices/timeslotSlice';
import { homecatReducer } from './redux/slices/homecatSlice';

import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';
import { baseurlReducer } from './redux/slices/baseUrlSlice';
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['products', 'category', 'timeslot', 'homecat'],
};
const reducer = combineReducers({
  counter: counterReducer,
  company: companyReducer,
  cart: cartReducer,
  category: categoryReducer,
  products: productReducer,
  timeslot: timeslotReducer,
  homecat: homecatReducer,
  baseurl: baseurlReducer,
});
//const persistedReducer = persistReducer(persistConfig,reducer);
export const store = configureStore({
  reducer: reducer, //persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      mmutableCheck: false,
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
