import React from 'react';
import {
  Image
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function Logo() {
  const companysetting  = useSelector((state) => state.company);
  let navigate = useNavigate();
  const base_url = useSelector(state => state.baseurl.baseURL);
  // useSelector(state => state.baseurl.baseURL);; //window.location.origin;        //window.location.origin; //"https://gadgets.ondemandcrm.co"; 
  const base_hostname = useSelector(state => state.baseurl.basehostname);//"bharatkrishna.ondemandcrm.co";  //window.location.hostname;  //window.location.hostname; 
  return (
    <>
      {companysetting.length > 0 &&
      <Image
          onClick={()=> navigate("/")}
          style={{height:"70px",width:"auto",cursor:"pointer"}}
          objectFit='cover'
          src={companysetting[0].DATA.company_logo != null ? `${base_url}/public/dmsfile/${base_hostname}/ecommerce/logo/${companysetting.length > 0 ? companysetting[0].DATA.company_logo : ""}` : `${base_url}/public/ecommerce_portal/shopsynclogoorginal.png`}
          alt='Logo'
        />
      }
      {/* /ecommerce Remove  */}
      {/* {base_hostname == "ecommerce.ondemandcrm.co" ? 
      <Image
          onClick={()=> navigate("/")}
          style={{height:"70px",width:"auto",cursor:"pointer"}}
          objectFit='cover'
          src={`${base_url}/public/ecommerce_portal/futura_shopsynclogo.png`}
          //src={`${base_url}/viewimage/getcompany/${companysetting.length > 0 ? companysetting[0].DATA.company.Companyinfo.logo_path : ""}`}
          alt='Logo'
        />
        :
        <Image
          onClick={()=> navigate("/")}
          style={{height:"70px",width:"auto",cursor:"pointer"}}
          objectFit='cover'
          src={`${base_url}/public/ecommerce_portal/bharatkrishnalogo.jpg`}
          //src={`${base_url}/viewimage/getcompany/${companysetting.length > 0 ? companysetting[0].DATA.company.Companyinfo.logo_path : ""}`}
          alt='Logo'
        />
      } */}
    </>
  )
}
