import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Container,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import Logo from '../header/Logo';
import { useDispatch, useSelector } from 'react-redux';

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function Footer() {
  const companysetting = useSelector(state => state.company);
  const maincolor =
    companysetting.length > 0 && companysetting[0].DATA.theme_maincolor != null
      ? companysetting[0].DATA.theme_maincolor
      : '#7c1c1c';
  const fontcolor =
    companysetting.length > 0 && companysetting[0].DATA.theme_fontcolor != null
      ? companysetting[0].DATA.theme_fontcolor
      : '#FFF';

  const baseURL = useSelector(state => state.baseurl.baseURL);
  console.log(baseURL);

  const basehostname = useSelector(state => state.baseurl.basehostname);
  console.log(basehostname);
  return (
    <Box
      bg={useColorModeValue(maincolor, maincolor)}
      color={useColorModeValue(fontcolor, fontcolor)}
    >
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 1fr' }}
          spacing={8}
        >
          <Stack spacing={6}>
            <Box>
              <Logo />
              {/* <Logo color={useColorModeValue('gray.700', 'white')} /> */}
            </Box>
            <Text fontSize={'sm'}>
              © 2022{' '}
              <Button
                color={fontcolor}
                variant="link"
                onClick={() => {
                  window.open('https://hofficecrm.com');
                }}
              >
                H-Office
              </Button>
              . All rights reserved
            </Text>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Company</ListHeader>
            <Link to="/aboutus">About</Link>
            <Link to="/contactus">Contact</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Support</ListHeader>
            <Link to={'/termsandcondition'}>Terms of Condition</Link>
            <Link to={'/privacypolicy'}>Privacy Policy</Link>
            <Link to={'/refundpolicy'}>Refund and Cancellation Policy</Link>
            <Link to={'/shippingpolicy'}>Shipping policy</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Follow Us</ListHeader>
            {baseURL == 'https://bharatkrishna.ondemandcrm.co' ? (
              <Link to="https://www.facebook.com/profile.php?id=100090983182455" target='_blank'>Facebook</Link>
            ) : (
              <Link to={'#'}>Facebook</Link>
            )}
            {baseURL == 'https://bharatkrishna.ondemandcrm.co' ? (
              <Link to="https://www.instagram.com/bharatkrishna_cakeandpastry/" target='_blank'>Instagram</Link>
            ) : (
              <Link to={'#'}>Instagram</Link>
            )}
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
