import {
  AspectRatio,
  Box,
  HStack,
  Image,
  Skeleton,
  Stack,
  Tag,
  Text,
  useColorModeValue,Badge
} from '@chakra-ui/react'
import * as React from 'react'
import { PriceTag } from './PriceTag'
import { ProductButtonGroup } from './ProductButtonGroup'
import { redirect, useNavigate } from "react-router-dom";
import { Base64 } from 'js-base64';
import { useSelector } from 'react-redux';


export const ProductCard = (props) => {
  const { product,category } = props;
  const pr_id = Base64.encode(product.product_id);
  const height_str_base = useSelector(state => state.baseurl.basehostname) == "fashion.ondemandcrm.co" ? "250px" : "";
  const height_str_lg = useSelector(state => state.baseurl.basehostname) == "fashion.ondemandcrm.co" ? "250px" : "";
  const height_str_xl = useSelector(state => state.baseurl.basehostname) == "fashion.ondemandcrm.co" ? "350px" : "";

  const currency1 = "INR";
  const base_url =  useSelector(state => state.baseurl.baseURL);; //window.location.origin;  //window.location.origin; //"https://gadgets.ondemandcrm.co"; //
  const host_url =  useSelector(state => state.baseurl.basehostname);
  // "bharatkrishna.ondemandcrm.co"; //window.location.hostname;   // "gadgets.ondemandcrm.co";
  const url_product_name = product.name.replace(/\s+/g, '-');
  let navigate = useNavigate();
  const navigate_product = () =>{
    if(product.transaction_type == 22 && product.current_stock < 1){

    }else{
      {/* /ecommerce Remove  */}
      navigate(`/product/${url_product_name.substring(0,25)}/${pr_id}`);
    }
  }
  return (
    <Stack 
      background={"#fff"} 
      boxShadow='lg'  
      spacing="3" 
      onClick={navigate_product} 
      cursor={(product.transaction_type == 22 && product.current_stock < 1) ? "no-drop" : "pointer"}
      >
      <Box position="relative"  className="group">
        <AspectRatio ratio={1} 
          height={{
            base: height_str_base,
            lg: height_str_lg,
            xl: height_str_xl,
          }}
        //height={height_str}
        >
          <Image
            src={`${product.original_photo_path}`}
            //src={`${base_url}/viewimage/getproduct/${product.photo_path}/normal`}
            alt={product.name}
            draggable="false"
            fallback={<Skeleton />}
            //borderTopRadius={15}
          />
        </AspectRatio>
        <HStack spacing="3" position="absolute" top="4" left="4">
          {product.tags?.map((tag) => (
            <Tag key={tag.name} bg={`${tag.color}.500`} color="white" fontWeight="semibold">
              {tag.name}
            </Tag>
          ))}
        </HStack>
        <Box
          opacity="0"
          transition="opacity 0.1s"
          _groupHover={{
            opacity: 1,
          }}
          position="absolute"
          bottom="3"
          left="3"
          right="3"
          bg={useColorModeValue('white', 'gray.800')}
          borderRadius="md"
          padding="1.5"
        >
          <ProductButtonGroup />
        </Box>
      </Box>
      <Box 
        transition="translateY(0px)"
        _groupHover={{
            translateY: -24,
          }}
      >
      {host_url == "gadgets.ondemandcrm.co" && (product.product_id == 31 || product.product_id == 40 || product.product_id == 43 || product.product_id == 32 || product.product_id == 34 || product.product_id == 36 || product.product_id == 30) &&
        <Badge variant='solid' colorScheme={'red'}>
          Limited time deal
        </Badge>
      }
      <Stack spacing="1" pl={2}>
        <Text>
          {product.name} 
        </Text>
        {/* <Rating defaultValue={product.rating} size="sm" /> */}
        {/* <HStack>
         <Text fontWeight="medium" fontSize="sm" color={useColorModeValue('gray.800', 'gray.200')}>
          {product.code}
          </Text>
        </HStack> */}
      </Stack>
      {product.transaction_type == 22 &&
      <Stack spacing="1" pl={2}>
        {product.current_stock > 0 ? <Text color="green.800"><b>In stock</b></Text> : <Text color="red.800"><b>Out of stock</b></Text>} 
      </Stack>
      }
      <PriceTag
        currency={currency1}
        price={(product.mrp_price > 0  && parseFloat(product.mrp_price) != parseFloat(product.price1_min) ) ? product.mrp_price : ''}
        salePrice={product.price1_min}
        priceProps={{
          fontWeight: 'bold',
          fontSize: 'sm',
          color: useColorModeValue('black', 'white'),
        }}
      />
        {host_url == "gadgets.ondemandcrm.co" && product.product_id == 32 &&
        <Stack spacing="1" ><Text><Tag>Flat INR 1000 Off on ICICI CreditCards</Tag></Text></Stack>
        }
        {host_url == "gadgets.ondemandcrm.co" && (product.product_id == 31 || product.product_id == 40) &&
        <Stack spacing="1" ><Text><Tag>Upto INR 8350 off on Exchange</Tag></Text></Stack>
        }
        {host_url == "gadgets.ondemandcrm.co" && (product.product_id == 34) &&
        <Stack spacing="1" ><Text><Tag>Upto INR 4350 off on Exchange</Tag></Text></Stack>
        }
        {host_url == "gadgets.ondemandcrm.co" && (product.product_id == 30 || product.product_id == 36 || product.product_id == 43) &&
        <Stack spacing="1" ><Image src={`${base_url}/public/images/discount_coupen.png`} /></Stack>
        }
      </Box>
    </Stack>
  )
}
