import { CloseButton,Box,ButtonGroup,Text, Center,Flex, Link, Select, useColorModeValue, IconButton, Button } from '@chakra-ui/react'
import * as React from 'react'
import { useNavigate } from 'react-router-dom';
import { PriceTag } from './PriceTag'
import { CartProductMeta } from './CartProductMeta'
import { QuantityPickerCart } from '../ProductDetail/QuantityPickerCart';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { incrementQuantity, decrementQuantity, removeItem} from '../../redux/slices/cartSlice';
import { useDispatch } from 'react-redux'

export const CartItem = (props) => {
  const dispatch = useDispatch();
  const {
    id,
    isGiftWrapping,
    title,
    description,
    quantity,
    image,
    currency,
    price,
    Tax,
    Shipping,
    TotalTax,
    Total,
    onChangeQuantity,
    onClickDelete,
    trans_type,
    newlengthid,
    matchedValues
  } = props;
  const currency1 = "INR";
  const currency2 = "";
  let navigate = useNavigate();
  return (
    <Flex
      direction={{
        base: 'column',
        md: 'row',
      }}
      justify="space-between"
      align="center"
    >
      <CartProductMeta
        name={title}
        description={description}
        image={image}
        isGiftWrapping={isGiftWrapping}
        existingAttributeValue={matchedValues}
      />

      {/* Desktop */}
      <Flex
        width="full"
        justify="space-between"
        display={{
          base: 'none',
          md: 'flex',
        }}
      >
            <ButtonGroup  size='xs'  mt={1} isAttached variant='outline'>
            <Button
              onClick={() => dispatch(decrementQuantity(newlengthid))}
            >
              <FiMinus />
            </Button>
            <Center minW="2">
              <Text as="span" mb={1} fontWeight="semibold" userSelect="none" ml={3} mr={3}>
                {quantity}
              </Text>
            </Center>
            <Button
              onClick={() => dispatch(incrementQuantity(newlengthid))}
            >
            <FiPlus />
            </Button>
            </ButtonGroup>
            <PriceTag  price={price} currency={currency1} />
            {trans_type == 22 &&
              <>
                <Text px={2} marginTop={1}>{Tax}</Text>
                <Text px={2} marginTop={1}>{TotalTax}</Text>
                <PriceTag price={Total} currency={currency1} />
              </>
            }
            <CloseButton aria-label={`Delete ${title} from cart`} onClick={() => dispatch(removeItem(newlengthid))} />
      </Flex>

      {/* Mobile */}
      <Flex
        mt="4"
        align="center"
        width="full"
        justify="space-between"
        display={{
          base: 'flex',
          md: 'none',
        }}
      >
        <Link fontSize="sm" textDecor="underline" onClick={() => dispatch(removeItem(id))} >
          Delete
        </Link>
          <ButtonGroup size='sm' isAttached variant='outline'>
            <Button
              onClick={() => dispatch(decrementQuantity(newlengthid))}
            >
              <FiMinus />
            </Button>
            <Center minW="1">
              <Text as="span" fontWeight="semibold" userSelect="none" ml={3} mr={3}>
                {quantity}
              </Text>
            </Center>
            <Button
              onClick={() => dispatch(incrementQuantity(newlengthid))}
            >
            <FiPlus />
            </Button>
          </ButtonGroup>
        {/* <QuantitySelect
          value={quantity}
          onChange={(e) => {
            onChangeQuantity?.(+e.currentTarget.value)
          }}
        /> */}
        <PriceTag price={price} currency={currency1} />
        
      </Flex>
    </Flex>
  )
}

