import { useDisclosure,Box, Button, Flex, HStack, Stack, useColorModeValue as mode,Menu,MenuButton,MenuList,MenuItem } from '@chakra-ui/react'
import Dropdown from './Dropdown';
import { useNavigate } from 'react-router-dom';
import { BiChevronDown } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import "../component/style.css";
import {changeCategoryLabel} from '../redux/slices/categorySlice';
const MenuItems = ({ items,isActive,setCatLabel }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const companysetting  = useSelector((state) => state.company);
    const fontcolor = (companysetting.length > 0 && companysetting[0].DATA.theme_fontcolor != null) ? companysetting[0].DATA.theme_fontcolor : "#FFF";
    const hovercolor = (companysetting.length > 0 && companysetting[0].DATA.theme_hovercolor != null) ? companysetting[0].DATA.theme_hovercolor : "#ab4c18";
    const catLabel = useSelector((state) => state.category.label);
  return (
    <>
      {items.Children ? (
        <>
            <Menu isOpen={isOpen}>
                {/* /ecommerce Remove  */}
                <MenuButton 
                    as={Button}  
                    size='md'
                    fontWeight='400'
                    variant='ghost' 
                    colorScheme='#22543d'  
                    className='navButtonCls' 
                    aria-current={catLabel === items.label ? 'page' : undefined}
                    rightIcon={<BiChevronDown />}
                    color={mode(`${fontcolor}`, `${fontcolor}`)}
                    paddingInlineStart={0}
                    _activeLink={{
                        borderBottomWidth: '2px',
                        borderColor: 'currentColor',
                        color: mode(`${fontcolor}`, `${fontcolor}`),
                        background:`${hovercolor}`,
                        padding:'6px',
                        fontWeight:'600',
                        borderRadius:'0px'
                    }}
                    _hover={{
                        color: mode(`${fontcolor}`, `${fontcolor}`),
                    }}
                    onMouseEnter={onOpen}
                    onMouseLeave={onClose}
                    onClick={()=> {navigate(`/filter/${items.label.replace(/\s+/g, '-')}`);dispatch(changeCategoryLabel(items.label));}}
                    >
                 {items.label}
                </MenuButton>
                {/* /ecommerce Remove  */}
                <MenuList onMouseEnter={onOpen} onMouseLeave={onClose}>
                    {items.Children.map((submenu, index) => (
                        <MenuItem textTransform="capitalize"  key={submenu.label} onClick={()=> {navigate(`/filter/${submenu.label.replace(/\s+/g, '-')}`);dispatch(changeCategoryLabel(items.label));}}>{submenu.label}</MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </>
        
      ) : (
        <Flex
            as="a"
            //href={href}
            onClick={()=> {navigate(`/filter/${items.label.replace(/\s+/g, '-')}`);dispatch(changeCategoryLabel(items.label));}}
            cursor="pointer"
            direction="column"
            justify="center"
            minH="10"
            fontSize="md"
            fontWeight="400"
            position="relative"
            textTransform="capitalize"
            aria-current={catLabel === items.label ? 'page' : undefined}
            color={mode(`${fontcolor}`, `${fontcolor}`)}
            _activeLink={{
                borderBottomWidth: '2px',
                borderColor: 'currentColor',
                color: mode(`${fontcolor}`, `${fontcolor}`),
                background:`${hovercolor}`,
                padding:'6px',
                fontWeight:'600'
            }}
            _hover={{
                color: mode(`${fontcolor}`, `${fontcolor}`),
            }}
            >
            
            {items.label}
            
            </Flex>
      )}
    </>
  );
};

export default MenuItems;