import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Container,
} from '@chakra-ui/react'
import { AppCart } from './cart/AppCart'
import DynamicButton from './DynamicButton'
import { useSelector,useDispatch } from 'react-redux'
import { useEffect } from 'react';
import { Header } from '../header/Header';
import Footer from '../Footer/Footer';
import {changeCategoryLabel} from '../redux/slices/categorySlice';

export default function Cart() {
    document.title = 'Home/Cart';
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart.cart);
    useEffect(()=>{
      window.scrollTo(0, 0);
      dispatch(changeCategoryLabel(""));
      const spinner = document.getElementById("spinner");
      if(spinner){
        setTimeout(() => {
          spinner.style.display = "none";
        }, 1500);
      }
    },['']);
    const getTotalQuantity = () => {
        let total = 0
        if(cart.length > 0){
          cart.forEach(item => {
            total += item.quantity
          })
        }
        return total
    }
    return (
      <>
        <Header/>
        <Box as={Container} maxW="7xl" mt={2} p={12} minH={'780px'}>
            <DynamicButton />
            <AppCart cartArray={cart} navigate={navigate} totalQty={getTotalQuantity() || 0} />
        </Box>
        <Footer/>
      </>
    )
}
