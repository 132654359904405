import React,{useEffect, useState} from 'react'
import Footer from '../Footer/Footer'
import DynamicButton from '../component/DynamicButton'
import HeaderCheckout  from '../header/HeaderCheckout'
import {
    Box,Container,Text,Stack,StackDivider,
    Accordion,AccordionItem,AccordionButton,AccordionPanel,AccordionIcon,Heading,
    Card,CardHeader,CardBody,
    RadioGroup,Radio,Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,CardFooter,Divider,
    ModalCloseButton,useDisclosure,FormControl,FormLabel,Input,FormHelperText,Select,SimpleGrid
  } from '@chakra-ui/react';
  
  import { useDispatch, useSelector } from 'react-redux';
  import http from "../http-common";
import { Address } from './Address';
export const CheckoutDetails = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const companysetting  = useSelector((state) => state.company);
    const maincolor = (companysetting.length > 0 && companysetting[0].DATA.theme_maincolor != null) ? companysetting[0].DATA.theme_maincolor : "#7c1c1c";
    const fontcolor = (companysetting.length > 0 && companysetting[0].DATA.theme_fontcolor != null) ? companysetting[0].DATA.theme_fontcolor : "#FFF";
    const [contact_add,setContactAddress] = useState([]);
    const [value, setValue] = useState();
    const [address, setAddress] = useState([]);
    const [accordionIndex, setAccordionIndex] = useState(0);
    useEffect(()=>{
        window.scrollTo(0, 0);
        //dispatch(changeCategoryLabel(""));
        const spinner = document.getElementById("spinner");
        if(spinner){
            setTimeout(() => {
            spinner.style.display = "none";
        }, 200);
    }
    },['']);
    const getAddressMaster = () =>{
        var contact_id = localStorage.getItem("contact_id");
        http.post(`/ecommerce_api/getContactAddress/`, {
            contact_id:contact_id,
        }).then(res => {
            if (res.data[0].STATUS == "SUCCESS") {
                setContactAddress(res.data[0].DATA.contact_address);
                setValue(res.data[0].billing_address_id);
            } else {
                setContactAddress([]);
            }
        }).catch(err => {
            console.log(err.message);
        });
    } 

    useEffect(()=>{
        getAddressMaster();
    },[localStorage.getItem("contact_id")]);
    const handlEditAddress = (addr_id) =>{
        http.post(`/ecommerce_api/getContactAddressByID/`, {
            address_id:addr_id,
        }).then(res => {
            if (res.data[0].STATUS == "SUCCESS") {
                setAddress(res.data[0].DATA);
                onOpen();
            } else {
                setAddress([]);
            }
        }).catch(err => {
            console.log(err.message);
        });
    }
    const handleAddNewAddress = () =>{
        setAddress([]); onOpen();
    }

    const handlDeleteAddress = (addr_id) =>{
        http.post(`/ecommerce_api/getDeleteAddressByID/`, {
            address_id:addr_id,
        }).then(res => {
            if (res.data[0].STATUS == "SUCCESS") {
                getAddressMaster();
            }
        }).catch(err => {
            console.log(err.message);
        });
    }

    return (
        <>
                <HeaderCheckout/>
                <Box as={Container} maxW="7xl" mt={2} p={12} minH={'780px'}>
                <Accordion allowToggle index={accordionIndex}>
                    <AccordionItem>
                        <h2>
                        <AccordionButton _expanded={{ bg: maincolor, color: fontcolor,fontWeight:600 }}> 
                            <Box as="span" flex='1' textAlign='left'>
                                1. Select a delivery address
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Card>
                                {/* <CardHeader>
                                    <Heading size='xs' textTransform='uppercase'>Your addresses</Heading>
                                </CardHeader> */}
                                <CardBody>
                                    <Stack divider={<StackDivider />} spacing='4'>
                                        <Box>
                                            <Heading size='xs' textTransform='uppercase'>
                                                Your addresses 
                                                <Button  colorScheme='teal' size='sm' float={'right'}  onClick={()=>handleAddNewAddress()}>
                                                    Add address
                                                </Button>
                                            </Heading>

                                        </Box>
                                        <Box>
                                            <RadioGroup onChange={setValue} value={value} >
                                                <Stack direction='column'>
                                                    {contact_add.length > 0 && contact_add.map((ad,i) => (
                                                        <Radio padding="10px" colorScheme='green' value={ad.address_id}>
                                                            <Text as='b'>{ad.nickname}</Text> - {ad.address} <Button colorScheme='teal' variant='link' onClick={()=>handlEditAddress(ad.address_id)}>Edit address</Button> | <Button colorScheme='teal' variant='link' onClick={()=>handlDeleteAddress(ad.address_id)}>Delete address</Button>
                                                        </Radio>
                                                    ))}
                                                </Stack>
                                            </RadioGroup>
                                            
                                        </Box>
                                        <Box>
                                            
                                        </Box>
                                    </Stack>
                                </CardBody>
                                <Divider />
                                <CardFooter>
                                    <Button colorScheme='yellow' size='sm' onClick={() => setAccordionIndex(1)}>
                                        Use this address
                                    </Button>
                                </CardFooter>
                            </Card>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton _expanded={{ bg: maincolor, color: fontcolor }}>
                            <Box as="span" flex='1' textAlign='left'>
                                2. Payment method
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <RadioGroup defaultValue='2'>
                                <Stack spacing={5} direction='row'>
                                    <Radio colorScheme='red' value='1'>
                                        Cash on Delivery/Pay on Delivery
                                    </Radio>
                                    <Radio colorScheme='green' value='2'>
                                        Online Payment
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
                <DynamicButton />
                </Box>
                <Modal closeOnOverlayClick={false} size="xl" isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalBody>
                            <Address onClose={onClose}  address={address} getAddressMaster={getAddressMaster}/>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            <Footer/>
        </>
    )
}
