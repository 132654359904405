import { ReactNode } from 'react';
import {
  Box,
  Flex,
  Avatar,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  Text
} from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import Logo from './Logo';
import { useDispatch, useSelector } from 'react-redux';
const NavLink = ({ children }) => (
  <Link
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    href={'#'}>
    {children}
  </Link>
);

export default function HeaderCheckout() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const companysetting  = useSelector((state) => state.company);
  const maincolor = (companysetting.length > 0 && companysetting[0].DATA.theme_maincolor != null) ? companysetting[0].DATA.theme_maincolor : "#7c1c1c";
  const fontcolor = (companysetting.length > 0 && companysetting[0].DATA.theme_fontcolor != null) ? companysetting[0].DATA.theme_fontcolor : "#FFF";
  return (
    <>
      <Box bg={useColorModeValue(maincolor, maincolor)} px="4" py="2">
        <Flex  h={16} alignItems={'center'} justifyContent={'space-between'}>
          <Logo/>
          <Center>
            <Text fontWeight={600} fontSize={28} color={fontcolor} fontStyle={'italic'}> checkout</Text></Center>
            <Flex alignItems={'center'}></Flex>
        </Flex>
      </Box>
    </>
  );
}
