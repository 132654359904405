// src/redux/cartSlice.js
import { createSlice,current  } from '@reduxjs/toolkit';
const getLocalStorageData = () =>{
    let newCartData = localStorage.getItem("CART");
    if(newCartData){
        return JSON.parse(newCartData);
    }else{
        return [];
    }
}
const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        cart: getLocalStorageData(),
        dummycarts:[],
        label: localStorage.getItem("phptoken") == "1" ? "Sign out" : "Sign in",
        pincodes: localStorage.getItem("pincodes") != "" ? localStorage.getItem("pincodes") : "",
        del_status : localStorage.getItem("del_status") != "" ? localStorage.getItem("del_status") : "",
    },
    reducers: {
        addToCart: (state, action) => {
            const itemInCart = state.cart.find((item) => item.id === action.payload.id);
            let cart_check = 0;
            if(itemInCart){
                const myExArray = current(state.cart);
                console.log(myExArray.length);
                if(myExArray.length > 0){
                    for(let i = 0; i < myExArray.length; i++){
                        if(action.payload.id === myExArray[i]['id']){
                            if(JSON.stringify(action.payload.val_attr) === JSON.stringify(myExArray[i]['val_attr'])){
                                cart_check = 1;    
                            }
                        }
                    }
                }else{
                    cart_check = 1;
                }
            }
            if(itemInCart && cart_check > 0) {
                itemInCart.quantity = itemInCart.quantity + action.payload.quantity;
            }else{
                state.cart.push({ ...action.payload, quantity: action.payload.quantity });
            }
            localStorage.setItem("CART",JSON.stringify(state.cart));
        },
        addToDummyCart:(state, action) =>{
            state.dummycarts = [];
            state.dummycarts.push({ ...action.payload, quantity: action.payload.quantity });
        },
        removeAllDummyCart:(state, action) =>{
            state.dummycarts = [];
        },
        transferDummyCartToMainCarts:(state, action) =>{
            state.cart.push({ ...state.dummycarts[0] });
            state.dummycarts = [];
            localStorage.setItem("CART",JSON.stringify(state.cart));
        },
        incrementQuantity: (state, action) => {
            console.log(action.payload);
            const item = state.cart.find((it) => it.newlengthid === action.payload);
            item.quantity++;
            localStorage.setItem("CART",JSON.stringify(state.cart));
        },
        decrementQuantity: (state, action) => {
            const item = state.cart.find((item) => item.newlengthid === action.payload);
            if (item.quantity === 1) {
                item.quantity = 1
            } else {
                item.quantity--;
            }
            localStorage.setItem("CART",JSON.stringify(state.cart));
        },
        removeItem: (state, action) => {
            const removeItem = state.cart.filter((item) => item.newlengthid !== action.payload);
            state.cart = removeItem;
            localStorage.setItem("CART",JSON.stringify(state.cart));
        },
        removeAllItem: (state, action) => {
            state.cart = [];
            localStorage.setItem("CART","");
        },
        changeAuthLabel: (state, action) => {
            state.label = action.payload;
        },
        changePincode: (state, action) => {
            state.pincodes = action.payload;
        },
        changeDeliveyStatus: (state, action) => {
            state.del_status = action.payload;
        },
    },
});
export const cartReducer = cartSlice.reducer;
export const {
    addToCart,
    addToDummyCart,
    removeAllDummyCart,
    transferDummyCartToMainCarts,
    incrementQuantity,
    decrementQuantity,
    removeItem,
    removeAllItem,
    changeAuthLabel,
    changePincode,
    changeDeliveyStatus
} = cartSlice.actions;