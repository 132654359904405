import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Icon,
    Stack,
    Text,
    useColorModeValue,
  } from '@chakra-ui/react';
  import { ReactElement } from 'react';
  import {
    FcAbout,
    FcAssistant,
    FcCollaboration,
    FcDonate,
    FcManager,
    FcAddressBook,
    FcPrivacy,
    FcInTransit
  } from 'react-icons/fc';
  import React,{useEffect, useState} from 'react'
import { Header } from '../header/Header';
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
//   interface CardProps {
//     heading: string;
//     description: string;
//     icon: ReactElement;
//     href: string;
//   }
  
  const Card = ({ heading, description, icon, href }) => {
    let navigate = useNavigate();
    return (
      <Box
        cursor="pointer"
        onClick={()=> {navigate(`/${href}`);}}
        maxW={{ base: 'full', md: '275px' }}
        w={'full'}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={5}>
        <Stack align={'start'} spacing={2}>
          <Flex
            w={16}
            h={16}
            align={'center'}
            justify={'center'}
            color={'white'}
            rounded={'full'}
            bg={useColorModeValue('gray.100', 'gray.700')}>
            {icon}
          </Flex>
          <Box mt={2}>
            <Heading size="md">{heading}</Heading>
            <Text mt={1} fontSize={'sm'}>
              {description}
            </Text>
          </Box>
          {/* <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
            Learn more
          </Button> */}
        </Stack>
      </Box>
    );
  };
  
  export default function AccountLayout() {
    let navigate = useNavigate();
    useEffect(()=>{
            window.scrollTo(0, 0);
            //dispatch(changeCategoryLabel(""));
            const spinner = document.getElementById("spinner");
            if(spinner){
                setTimeout(() => {
                spinner.style.display = "none";
            }, 200);
        }
    },['']);
    return (
        <>
            <Header/>
              <Box p={4} mb={80} mt={10}>
                  <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
                    <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>Your Account</Heading>
                  </Stack>
                  <Container maxW={'7xl'} mt={12}>
                    <Flex flexWrap="wrap" gridGap={6} justify="center">
                        <Card
                        heading={'Your Orders'}
                        icon={<Icon as={FcInTransit} w={10} h={10} />}
                        description={
                            'Track, return, or buy things again'
                        }
                        href={'#'}
                        />
                        <Card
                        heading={'Profile & Security'}
                        icon={<Icon as={FcPrivacy} w={10} h={10} />}
                        description={
                            'Edit login, name, and mobile number'
                        }
                        href={'profile'}
                        />
                        <Card
                        heading={'Your Addresses'}
                        icon={<Icon as={FcAddressBook} w={10} h={10} />}
                        description={
                            'Edit addresses for orders and gifts'
                        }
                        href={'#'}
                        />
                        <Card
                        heading={'Contact Us'}
                        icon={<Icon as={FcAssistant} w={10} h={10} />}
                        description={
                            ''
                        }
                        href={'#'}
                        />
                    </Flex>
                  </Container>
              </Box>
            <Footer />
        </>
    );
  }
  